<html>

<head>
  <meta charset='utf-8'>
  <meta http-equiv='X-UA-Compatible' content='IE=edge'>
  <meta name='viewport' content='width=device-width, initial-scale=1'>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
  crossorigin="anonymous">

  <!-- nav font -->
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <!-- fafa -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
</head>

<body>
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark bb" id="xy">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon "></span>
    </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li><a [routerLink]="['/userhome']" id="colarr">home</a></li>
        <li><a [routerLink]="['/myprofile']" id="colarr">myprofile</a></li>
        <li><a [routerLink]="['/useroffer']" id="colarr">Offers</a></li>

        <!-- <li class="nav-item"><a href="track.html" id="colarr">track</a></li> -->
        <li class="nav-item"><a [routerLink]="['/userfeedback']" id="colarr">feedback</a></li>
        <li class="nav-item"><a id="colarr" [routerLink]="['/']" (click)="logoutuser()">logout</a></li>
        <li class="nav-item"></li>

      </ul>
      <a [routerLink]="['/cart']" class="notification">
        <i class="fa fa-shopping-cart" id="ct"></i>
        <!-- <span class="badge" id="notycount">0</span> -->
      </a>
    </div>
  </nav>

</body>

</html>