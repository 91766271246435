
<!doctype html>
<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">

  </head>
  <body >
    <app-navbar></app-navbar>
    <table class="table table-hover w-50 h-30" style="background-color: white;   box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.5);">

        <tbody>
          <tr>
            <th scope="row">Name</th>

            <td>{{name}}</td>

          </tr>
          <tr>

            <th scope="row">Email</th>
            <td >{{email}}</td>

          </tr>
          <tr>
            <th scope="row">Phone no</th>
            <td >{{contact}}</td>

          </tr>
          <tr>
              <th><a  [routerLink]="['/editprofile']">Edit profile </a> </th>
              <th><a [routerLink]="['/change-password']">Change password </a></th>
          </tr>
        </tbody>
      </table>


  </body>
</html>
