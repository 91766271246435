<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Concert+One|Oswald|Quicksand&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap" rel="stylesheet">

  <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
</head>

<body>
  <section class="qu" id="o">

    <div class="container" style="margin-bottom: 50px;">
      <h1 style="text-align: center; padding-top: 50px; padding-bottom: 50px;"
        class="animated zoomIn  infinite font-poppins">Special Offers</h1>
      <div class="row">

        <div class="col-md-4 col-lg-4 ">
          <div class="card text-center" style="height: auto;">
            <div class="card-header">
              <h4>Wednesday</h4>
            </div>
            <img src=" ../../../assets/pic/30.jpg" style="height: 400px;" class="img-fluid">
            <div class="card-body">
              <p class="card-text font-poppins">Get Discount up to 15%!!!!</p>
            </div>
            <div class="card-footer">
              <a [routerLink]="['/login']">Purchase</a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-lg-4" >
          <div class="card text-center">
            <div class="card-header">
              <h4>Saturday</h4>
            </div>
            <img src=" ../../../assets/pic/85.jpg" style="height: 400px;" class="img-fluid">
            <div class="card-body">
              <p class="card-text font-poppins">Get Discount up to 25%!!!!</p>
            </div>
            <div class="card-footer">
              <a [routerLink]="['/login']">Purchase</a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-lg-4" >
          <div class="card text-center">
            <div class="card-header">
              <h4>Sunday</h4>
            </div>
            <img src=" ../../../assets/pic/9.jpg" style="height: 400px;" class="img-fluid">
            <div class="card-body">
              <p class="card-text font-poppins">Get Discount up to 51%!!!!</p>
            </div>
            <div class="card-footer">
              <a [routerLink]="['/login']">Purchase</a>
            </div>
          </div>
        </div>

      </div>
</div>
  </section>

  <!-- nice to slice you -->
  <section id="s5" style="margin-top: 75px;">
    <h1 style="text-align: center; padding-top: 100px;" class="display-2">slice to meet you</h1>
  </section>



  <section>
    <footer class="page-footer mb-4">
      <div class="footer-copyright text-center py-3" style="color: tomato;"></div>
    </footer>
  </section>

</body>

</html>