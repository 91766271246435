<!DOCTYPE html>
<html lang="en">
<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">

  <!-- fafa -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

</head>

<body>

  <div class="container">
    <div class="page-wrapper bg-gra-01 p-t-180 p-b-100 font-poppins">
      <div class="wrapper wrapper--w780">
        <div class="card card-3 iregister">
          <div class="card-body">
            <h2 class="title">Registration Info</h2>
            <div  style="color: red;" *ngIf="avail" >
            <h3>  {{msg}}</h3>
            </div>
            <!-- <div style="color: red;">
              value = {{f.value | json}}
              valid = {{f.valid}}
            </div> -->
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
              <div class="input-group">
                <input class="input--style-3" type="text" placeholder="Name" name="name" autocomplete="off" ngModel required #name="ngModel">
              </div>
              <!-- <div style="color: red;">
                name value = {{name.value}}
                valid = {{name.valid}}
                dirty = {{name.dirty}}
                touched = {{name.touched}}
              </div> -->

              <div class="input-group">
                <input class="input--style-3" type="email" placeholder="Email" name="email" autocomplete="off" ngModel email
                 required #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
              </div>
              <div class="input-group">
                <input class="input--style-3" type="text" placeholder="Phone" name="contact"
                  pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$" autocomplete="off" ngModel required #contact="ngModel">
              </div>
              <div class="input-group">
                <input class="input--style-3" type="password" placeholder="Enter password" name="p1" id="p1"
                  autocomplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Plaese enter valid and strong password" ngModel required #p1="ngModel">
              </div>
              <!-- <div id="message">
                <p id="letter" class="invalid">A lowercase letter</p>
                <p id="capital" class="invalid">A capital (uppercase) letter</p>
                <p id="number" class="invalid">A number</p>
                <p id="length" class="invalid">Minimum 8 characters</p>
              </div> -->

              <div class="input-group">
                <input class="input--style-3" type="password" placeholder="Re-enter password" name="p2" id="p2"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Plaese enter valid and strong password"
                  autocomplete="off" ngModel required #p2="ngModel">
              </div>
              <div class="p-t-10">
                <!-- <center> -->
                  <button class="btn btn--pill btn--green" [disabled]="f.invalid" type="submit">Register</button>
                  <button class="btn btn--pill btn--green" style="margin-left: 10px;" type="reset">Reset</button>
                <!-- </center> -->
              </div>



            </form>

            <div class="niche">
              <!-- <center> -->
                <a [routerLink]="['/']"><i class="fa fa-arrow-circle-left" aria-hidden="true" style="color:white;">
                    Goback</i></a>
                <a [routerLink]="['/login']"  id="fp">Login?</a>
              <!-- </center> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</body>

</html>