<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">

</head>

<body>


  <div class="container">
    <div class="page-wrapper p-t-180 p-b-100 font-poppins">
      <div class="wrapper wrapper--w780">
        <div class="card card-3 iforgot">
          <div class="card-body">
            <h2 class="title">Change Password</h2>
            <form>
              <div class="input-group">

                <input class="input--style-3" type="text"
                   name="email" required  autocomplete="off" disabled
                  value="we sended you otp click below link for change password">
              </div>


            </form>
            <div class="niche">
              <!-- <center> -->
              <a [routerLink]="['/reset-password-done']">change-password</a>
              <a [routerLink]="['/login']"><i class="fa fa-arrow-circle-left" aria-hidden="true" style="color:white;margin-left: 10px;">
                  Goback</i></a>
              <!-- </center> -->
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

</body>

</html>