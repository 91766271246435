<!DOCTYPE html>
<html lang="en">

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">

  <!-- fafa -->
  <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->

</head>

<body>
  <div class="container">
    <div class="page-wrapper bg-gra-01 p-t-180 p-b-100 font-poppins">
      <div class="wrapper wrapper--w780">
        <div class="card card-3 ilogin">
          <div class="card-body">

            <h2 class="title">Login Info</h2>

            <div  style="color: rgb(227, 47, 161);" *ngIf="avail" >
              <h3>  {{msg}}</h3>
            </div>


            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
              <div class="input-group">
                <i class="fa fa-user" aria-hidden="true" style="color: white;"></i>
                <input class="input--style-3" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Enter Your Email" name="email" email ngModel required #email="ngModel"
                  autocomplete="off">
              </div>
              <div class="input-group">
                <i class="fa fa-key" aria-hidden="true" style="color: white;"></i>
                <input class="input--style-3" type="password" placeholder="Enter password" name="password" autocomplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Plaese enter valid and strong password" ngModel required #password="ngModel">
              </div>
              <div class="p-t-10">
                <!-- <center> -->
                <button class="btn btn--pill btn--green" type="submit" [disabled]="f.invalid">Login</button>

                <button class="btn btn--pill btn--green" style="margin-left: 10px;" type="reset">Reset</button>
                <!-- </center> -->
              </div>
            </form>
            <div class="niche">
              <!-- <center> -->
              <a [routerLink]="['/reset']" id="fp">Forgot password?</a>
              <a [routerLink]="['/register']" routerLinkActive="active" id="fp">Don't have account?</a>
              <a [routerLink]="['/']"><i class="fa fa-arrow-circle-left" aria-hidden="true"
                  style="color:white;">Goback</i></a>
              <!-- </center> -->
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>


</body>

</html>