<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Concert+One|Oswald|Quicksand&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap" rel="stylesheet">

  <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
</head>

<body>


  <!-- cooks -->
  <section id="chef">
    <!-- <h1 style="text-align: center; padding-top: 40px; padding-bottom: 40px;"
      class="animated pulse infinite font-poppins tomato">Our Master Chef</h1> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3" >
          <img src="assets/pic/111.jpg" class="img-fluid">
          <div class="text-center pt-1">
            <h4>Nita-Mehta</h4>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <img src="assets/pic/chef-2.jpg " class="img-fluid">
          <div class="text-center pt-1">
            <h4>Rebeca Welson</h4>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <img src="assets/pic/chef-3.jpg" class="img-fluid">
          <div class="text-center pt-1">
            <h4>Kharl Branyt</h4>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <img src="assets/pic/chef-4.jpg" class="img-fluid">
          <div class="text-center pt-1">
            <h4>Luke Simon</h4>
          </div>
        </div>
      </div>
    </div>
  </section>



</body>

</html>