import { Component, OnInit } from '@angular/core';
// import * as AOS from 'aos';
@Component({
  selector: 'app-famous',
  templateUrl: './famous.component.html',
  styleUrls: ['./famous.component.css']
})
export class FamousComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // AOS.init();
  }


}
