<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Concert+One|Oswald|Quicksand&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap" rel="stylesheet">

  <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
</head>

<body>

  <h1 style="text-align: center; padding-top: 40px; padding-bottom: 40px;"
    class="animated pulse  infinite font-poppins">Our Famous food items</h1>

  <div class="container">
    <div class="row" id="f">
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card" >
          <img src="assets/pic/96.jpg" class="img-fluid" style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Spicy chicken biryani</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/95.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Special mutton biryani</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/85.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Hyderabadi-Veg-Biryani</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/115.jpg" class="img-fluid" style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Chicken-burger</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/116.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Indian-veggie-burger</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/117.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Veg cheese burger</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/20.jpg" class="img-fluid" style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Fresh Veggie</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src=" assets/pic/21.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Veggie Paradise</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card">
          <img src="assets/pic/22.jpg" class="img-fluid" alt="..." style="width: 100%;height: auto;">
          <div class="card-body">
            <h5 class="card-title" style="text-align: center;">Cheese N Corn</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>