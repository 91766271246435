<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <link
        href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
        rel="stylesheet">

</head>

<body>


    <div class="container">
        <div class="page-wrapper p-t-180 p-b-100 font-poppins">
            <div class="wrapper wrapper--w780">
                <div class="card card-3 iforgot">
                    <div class="card-body">
                        <h2 class="title">Forgot Password</h2>
                        <div  style="color: red;" *ngIf="avail" >
                          <h3>  {{msg}}</h3>
                          </div>
                        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                            <div class="input-group">

                                <input class="input--style-3" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" type="email" placeholder="Enter Your Email" name="email" ngModel required #email="ngModel"
                                  email
                                  autocomplete="off">
                            </div>

                            <div class="p-t-10">
                            <!-- <center> -->
                              <button class="btn btn--pill btn--green" [disabled]="f.invalid" type="submit">submit</button>
                            <!-- </center> -->

                            </div>
                        </form>
                        <div class="niche">
                        <!-- <center> -->
                          <a [routerLink]="['/login']"><i class="fa fa-arrow-circle-left" aria-hidden="true"
                                    style="color:white;"> Goback</i></a>
                                  <!-- </center> -->
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>

</body>

</html>