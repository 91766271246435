<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Concert+One|Oswald|Quicksand&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap" rel="stylesheet">


  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>



  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>

<body>
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark bb" id="xy">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon "></span>
    </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin']" id="colarr">Home</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/viewuser']" id="colarr">view Users</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/viewpizza']" id="colarr">View Food</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/addpizza']" id="colarr">ADD Food</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/admin/viewfeedback']" id="colarr">View Feedback</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/']" (click)="logoutadmin()" id="colarr">Log Out</a></li>
      </ul>
    </div>
  </nav>

</body>

</html>