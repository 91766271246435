<!doctype html>
<html lang="en">

<head>
  <!-- Required meta tags -->
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

  <!-- Bootstrap CSS -->

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">

</head>

<body>
  <app-ind></app-ind>
  <table class="table table-hover w-50"
    style="background-color: white;   box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.5);">
    <thead>
      <tr>
        <th>Index</th>
        <th scope="col">Pizza </th>
        <th scope="col">Pizza Name</th>
        <th scope="col">Update</th>
        <th scope="col">Delete</th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let pizza of pizzas;index as i">

        <th scope="row">{{i+1}}</th>
        <td><img src="../../../assets/pizza/{{pizza.pizzaimage}}" alt="" height=100 width=100 /></td>
        <td>{{pizza.pizzaname}}</td>
        <td><button class="btn btn-link" href="#" (click)="editpizza(pizza)"><i class="fas fa-edit" aria-hidden="true"></i></button></td>
        <td><button class="btn btn-link" (click)="deletepizza(pizza)"><i class="fa fa-trash" aria-hidden="true"></i></button></td>
      </tr>

    </tbody>
  </table>

</body>

</html>