<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
    rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Audiowide&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css?family=Concert+One|Oswald|Quicksand&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap" rel="stylesheet">

  <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
</head>

<body>
  <nav class="navbar  navbar-expand-lg bg-dark navbar-dark bb" id="xy">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon "></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item" ><a class="nav-link" href="#f" id="colarr">Famous food items</a></li>
        <li class="nav-item"><a class="nav-link" href="#o" id="colarr">Offers</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/register']" id="colarr">Register</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/login']" id="colarr">Login</a></li>
        <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/admin']" id="colarr">Admin</a></li> -->
      </ul>
    </div>
  </nav>
  <div class="header">
    <div class="animated pulse infinite">
      <h1 id="hdr" style="padding-left: 10%; padding-top:20%;" class="display-3">it’s food time!!</h1>
    </div>
  </div>


  <footer>
    <div class="scrolltop float-right">
      <i class="fa fa-arrow-up " onclick="topFunction()" id="mybtn"></i>
    </div>
  </footer>

</body>

</html>